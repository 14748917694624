import React from 'react'
import { graphql, Link } from 'gatsby'
import PageHelmet from '../components/PageHelmet'

const Advertise = ({ data }) => {
  const pageContent = data.advertise.edges[0]
  const {
    node: {
      bulletPoints,
      contactFormIntro,
      pageHeading,
      selfServiceAdsContent,
      selfServiceTitle,
    },
  } = pageContent

  const styles = `
    h1 {
        padding-bottom: 0.5rem;
        border-bottom: 1px solid #dee2e6;
    }
    .advertise-intro {
        margin-bottom: 40px;
    }
    .advertise-contact-form {
        width: 100%;
    }
  `

  return (
    <>
      <PageHelmet title="Advertise" />
      <style dangerouslySetInnerHTML={{ __html: styles }} />
      <section className="advertise-intro">
        <h1 className="mb-4">{pageHeading}</h1>
        <ul>
          <div dangerouslySetInnerHTML={{ __html: bulletPoints }} />
        </ul>
      </section>

      <div className="row">
        <section className="col-md-6">
          <p className="mb-2">{contactFormIntro}</p>
          <iframe
            className="advertise-contact-form"
            src="https://docs.google.com/forms/d/e/1FAIpQLSdzA2bq-94pfFYLjYnbsKfqiwhvM2afeW1tPT7wSmJWUsij1g/viewform?embedded=true"
            width="640"
            height="812"
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
            title="advertise-contact-form"
          >
            Loading…
          </iframe>
        </section>
        <section className="col-md-6">
          <h2 className="pt-0">{selfServiceTitle}</h2>
          <p>{selfServiceAdsContent}</p>
          <Link
            to="https://tests.wikijob.co.uk/advertise"
            className="btn btn-orange"
          >
            Self service ad booking
          </Link>
        </section>
      </div>
    </>
  )
}

export default Advertise

export const query = graphql`
  query advertiseQuery {
    advertise: allDatoCmsAdvertise {
      edges {
        node {
          bulletPoints
          contactFormIntro
          pageHeading
          selfServiceAdsContent
          selfServiceTitle
        }
      }
    }
  }
`
